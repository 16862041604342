:root {
    --main-bg-color: #dddddd;
    --main-contrast-color-dark: #222831;
    --main-accent-color: #f05454;
    --main-contrast-color: #30475e;
    --white: #ffffff;
    --black: #000000;
    --contrast-bg-color: #ffffff;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

/* NAVBAR */
nav ul {
    background-color: var(--main-contrast-color);
    overflow: hidden;
    color: white;
    padding: 0;
    margin: 0;
    text-align: center;
    -webkit-transition: max-height 0.4s;
    -ms-transition: max-height 0.4s;
    -moz-transition: max-height 0.4s;
    -o-transition: max-height 0.4s;
    transition: max-height 0.4s;
}

nav ul li {
    display: inline-block;
    padding: 20px;
}

nav ul li:hover {
    background-color: var(--main-accent-color);
}


section {
    line-height: 1.5em;
    font-size: 0.9em;
    padding: 40px;
    width: 75%;
    margin: 0 auto;
}

.handle {
    width: 100%;
    background: var(--main-contrast-color);
    text-align: left;
    box-sizing: border-box;
    padding: 15px 10px;
    cursor: pointer;
    color: white;
    display: none;
}

@media screen and (max-width: 900px) {

    nav ul {
        max-height: 0;
    }

    .showing {
        max-height: 20em;
    }

    nav ul li {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        text-align: left;
    }

    .handle {
        display: block;
    }
}
