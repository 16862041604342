* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

.footer {
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 70px 0;
    box-sizing: border-box;
    background-color: var(--main-contrast-color);
}

.inner_footer {
    display: block;
    margin: 0 auto;
    width: 1100px;
    height: 100%;
}

.inner_footer .logo_container {
    width: 35%;
    float: left;
    height: 100%;
    display: block;
}

.inner_footer .logo_container img {
    width: 120px;
    height: auto;
}

.inner_footer .footer_third {
    width: calc(21.6% - 20px); /* width: calc(21.6% - 20px); calc(33.3% - 20px); */
    margin-right: 10px;
    float: left;
    height: 100%;
}

.inner_footer .footer_third:last-child {
    margin-right: 0;
}

.inner_footer .footer_third h1 {
    font-family: "Lexend Deca", sans-serif;
    font-size: 22px;
    color: var(--white);
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.inner_footer .footer_third a {
    font-family: "Lexend Deca", sans-serif;
    font-size: 16px;
    color: var(--white);
    display: block;
    width: 100%;
    padding-bottom: 5px;
}

.inner_footer .footer_third a:hover {
    font-family: "Lexend Deca", sans-serif;
    font-size: 20px;
    color: var(--white);
    font-weight: bold;
    display: block;
    width: 100%;
    padding-bottom: 5px;
}

.inner_footer .footer_third li {
    display: block;
    padding: 0 5px;
    font-size: 20px;
}

.inner_footer .footer_third span {
    color: var(--white);
    font-family: "Lexend Deca", sans-serif;
    font-size: 16px;
    font-weight: 200;
    display: block;
    width: 100%;
    padding-top: 20px;
}

@media (max-width: 600px) {
    .logo_container {
        text-align: center;
    }

    .footer {
        padding: 20px 0;
    }

    .footer .inner_footer {
        width: 90%;
    }

    .inner_footer .logo_container,
    .inner_footer .footer_third {
        margin-bottom: 30px;
        width: 100%;
    }

    .footer_third {
        padding-top: 20px;
    }
}

.footer_copyright {
    background-color: var(--white);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    box-sizing: border-box;
}

.footer_copyright a {
    color: var(--main-contrast-color-dark);;
    font-size: 16px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.footer_copyright a:hover,
.footer_copyright .link:hover {
    font-size: 16px;
    font-weight: bold;
    color: var(--main-contrast-color-dark);;
}

.footer_copyright .link {
    margin-left: 10px;
    margin-right: 10px;
}

.footer_copyright p {
    color: var(--main-contrast-color-dark);;
    font-size: 16px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.footer_copyright span {
    color: var(--main-contrast-color-dark);;
    padding: 10px;
    font-size: 16px;
}

.activeLink {
    font-weight: bold;
}

.footer_copyright .SocialNetworkName {
    font-family: "Lexend Deca", sans-serif;
    font-size: 20px;
    color: var(--white);
    font-weight: bold;
}

.footer_copyright .SocialButtonContainer {
    padding: 10px 30px;
    background-color: var(--main-contrast-color-dark);
    margin: 10px;
    border: none;
    cursor: pointer;
    outline: none !important;
    border-radius: 10px;
}

.FeaturesWrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    opacity: 1;
    background-color: var(--white);
    text-align: center;
}

.FeaturesWrapper img {
    opacity: 0.5;
    padding-left: 10px;
    padding-right: 10px;
}

.FeaturesWrapper img:hover {
    opacity: 1;
}

.FeaturesWrapper hr {
    background-color: var(--main-contrast-color-dark);;
    width: 50px;
    height: 5px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}
