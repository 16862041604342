.FullScreenImage {
    width: 100%;
    height: auto;
    position: relative;
}

.FullScreenImageWrapper {
    color: #ffffff;
}

.FullScreenImageOptimized {
    height: 100%;
    width: auto;
}

.FullScreenImage img {
    width: 100%;
    height: auto;
}

.FullScreenImage p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-family: "Lexend Deca", sans-serif;
    font-size: 70px;
    text-align: center;
    font-weight: bold;
}

.FeaturesWrapper {
    padding: 40px;
    background-color: #000000;
    text-align: center;
}

.FeaturesWrapper hr {
    background-color: #0b132b;
    width: 50px;
    height: 5px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.FeaturesWrapper p {
    color: #0b132b;
    font-family: "Lexend Deca", sans-serif;
    font-size: 16px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
}

.FeaturesWrapper h2 {
    padding: 40px;
    color: #0b132b;
    font-family: "Lexend Deca", sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.FeaturesWrapperPrimaryColor {
    max-width: 100%;
    background-color: #ffffff;
    text-align: center;
    margin: 0px;
    padding: 40px;
}

.FeaturesWrapperPrimaryColor hr {
    background-color: #000000;
    width: 50px;
    height: 5px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.FeaturesWrapperPrimaryColor p {
    color: #000000;
    font-family: "Lexend Deca", sans-serif;
    font-size: 16px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
}

.FeaturesWrapperPrimaryColor h2 {
    padding: 40px;
    color: #000000;
    font-family: "Lexend Deca", sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.FeaturesWrapperPrimaryColor h3 {
    padding: 40px;
    color: #000000;
    font-family: "Lexend Deca", sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.Card {
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 25%;
    width: 25%;
}

.Card h3 {
    padding: 10px;
}

.Card p {
    max-width: 80%;
    padding: 10px;
}

.FeaturesRow {
    margin: 0px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.CenterItems {
    display: inline-block;
    margin-left: auto !important;
    margin-right: auto !important;
}

.Row {
    display: flex !important;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .FullScreenImage p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-family: "Lexend Deca", sans-serif;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
    }

    .Row {
        display: block !important;
    }

    .Card {
        margin: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 100%;
        width: 100% !important;
    }

    .FeaturesWrapper h2 {
        padding: 15px;
        font-size: 20px;
    }

    .FeaturesWrapper {
        padding: 10px;
    }

    .FeaturesWrapper p {
        padding: 15px;
        font-size: 14px;
    }

    .FeaturesWrapperPrimaryColor p {
        padding: 15px;
        font-size: 14px;
    }

    .FeaturesWrapperPrimaryColor h2 {
        padding: 15px;
        font-size: 20px;
    }

    .FeaturesWrapperPrimaryColor {
        padding: 10px;
    }

    .ArticlePreviewImage {
        width: 100% !important;
    }

    .ArticleTitle {
        font-size: 20px !important;
    }

    .CarouselDiv {
        width: 100% !important;
    }
}

.simpleButton:hover {
    color: #ffffff;
}

.lowPaddingParagraph {
    padding: 10px !important;
}

.inactiveSimpleButton {
    font-family: "Lexend Deca", sans-serif;
    font-size: 20px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none !important;
    color: gray;
}

.FeaturesWrapper .simpleButton {
    font-family: "Lexend Deca", sans-serif;
    font-size: 20px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none !important;
    color: #0b132b;
}

.FeaturesWrapper .simpleButton:hover {
    color: #ffffff;
}

.FeaturesWrapperPrimaryColor .simpleButton {
    font-family: "Lexend Deca", sans-serif;
    font-size: 20px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none !important;
    color: #000000;
}

.FeaturesWrapperPrimaryColor .simpleButton:hover {
    color: #00ffff;
}

.ArticlePreviewImage {
    max-width: 1000px;
    height: auto;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.ArticleWrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.Tag {
    display: inline-block;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: #fff;
}

/* THE BADGES FOR THE TAGS WHEN THERE IS A WHITE BACKGROUND */

.First_tag {
    margin-left: 15px;
}

.Tech_tag {
    background-color: #6610f2;
}

.Politics_tag {
    background-color: red;
}

.Date_tag {
    background-color: black;
}

.Author_tag {
    background-color: black;
}

.Travel_tag {
    background-color: #ff7f50;
}

.Podcast_tag {
    background-color: darkolivegreen;
}

.Economy_tag {
    background-color: #1c5734;
}

.Agro_tag {
    background-color: rgb(9, 97, 46);
}

.Justice_tag {
    background-color: #224ad1;
}

.Interview_tag {
    background-color: #7b19f0;
}

.Social_tag {
    background-color: #2d09c0;
}

.ArticleShortDescription {
    text-align: left;
}

.ArticleParagraph {
    text-align: left;
    padding: 0px !important;
    margin-top: 30px;
}

.ArticleTitle {
    text-align: center;
    padding: 0px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    font-weight: bold;
}

.CarouselDiv {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
